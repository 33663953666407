.title {
    text-align: center;
    font-size: 28px;
}

.paragraph {
    margin-top: 50px;
}

.head {
    font-weight: bold;
}