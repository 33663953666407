.container {
  width: 100%;
  /* height: 200px; */
  /* overflow: hidden; */
}
.container img {
  width: 100%;
  background-repeat : no-repeat;
  background-size : cover;
}

.contsbox {
  text-align: center;
  font-size: 20px;
}

.title {
  font-family: Pacifico;
  font-size: 36px;
}

.privacy {
  margin-top: 30px;
  margin-bottom: 30px;
}

.mailicon:link {
  color: black;
}

.mailicon:hover {
  color: rgb(160, 160, 160);
}